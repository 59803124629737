import React from "react"
// import ReactDOM from "react-dom"
import CookiesVisual from "./CookiesVisual"

//import { render } from 'react-dom'
import { createOvermind } from 'overmind'
import { config } from '../../overmind/index'
import { Provider as OvermindProvider } from 'overmind-react'
import {RenderPlugin} from "../../components/Plugins/FiducPlugin";

const overmind = createOvermind(config, { devtools: false })

export const Index = ({pluginParams}) => (
	<OvermindProvider value={overmind}>
		<CookiesVisual pluginParams={pluginParams} />
	</OvermindProvider>
)

RenderPlugin('CookiesVisual', Index)



